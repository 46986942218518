import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  error?: boolean
  name: string
  label?: string
  required?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    { error = false, name, label, required, onBlur, onChange, onFocus },
    ref,
  ) {
    const id = `#form-${name}`

    return (
      <Container dial={4} inline row>
        <InputField
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 1.875rem;
  position: relative;
`

const InputField = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ label {
    &:before {
      border-color: ${({ theme }) => theme.colors.variants.neutralDark4};
    }
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralDark2};
      opacity: 1;
    }
  }
`

const Label = styled.label<ContainerProps>`
  padding-left: 1.5rem;
  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
  }
  &:before {
    width: 0.9375rem;
    height: 0.9375rem;
    border: 0.125rem solid
      ${({ error, theme }) =>
        error
          ? theme.colors.variants.dangerLight
          : theme.colors.variants.neutralLight2};
    top: 0.125rem;
    left: 0;
  }
  &:after {
    width: 0.4375rem;
    height: 0.4375rem;
    background: ${({ theme }) => theme.colors.variants.dangerLight};
    top: 0.375rem;
    left: 0.25rem;
    opacity: 0;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }
`

interface ContainerProps {
  error: boolean
}
