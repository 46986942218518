import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  disabled?: boolean
  id?: string
  name: string
  type?: string
  placeholder?: string
  error?: boolean
  isDirty?: boolean
  required?: boolean
  variant?: Variant
  value?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Input = memo(
  forwardRef<HTMLInputElement, Props>(function Input(
    {
      disabled,
      id,
      name,
      type,
      placeholder,
      error = false,
      isDirty = false,
      required,
      variant = 'text',
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        id={id}
        isDirty={isDirty}
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        variant={variant}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    )
  }),
)

Input.displayName = 'Input'

const Container = styled.input<ContainerProps>`
  ${({ theme, error, variant }) => {
    return css`
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 100px black inset;
        -webkit-text-fill-color: ${theme.colors.variants.neutralLight3};
      }

      ${variant === 'submit'
        ? css`
            display: block;
            width: 100%;
            height: 3rem;
            color: ${theme.colors.variants.neutralLight4};
            font-family: ${theme.fontFamily.paragraph};
            font-size: 0.875rem;
            font-weight: 400;
            letter-spacing: 0.0875rem;
            padding: 0 1.25rem;
            position: relative;
            text-transform: uppercase;
            transition: 0.3s ease-in-out;
            z-index: 2;
            &:disabled {
              background-color: ${theme.colors.variants.neutralDark4};
              cursor: not-allowed;
            }
          `
        : css`
            width: 100%;
            height: 2rem;
            font-family: ${theme.fontFamily.paragraph};
            font-size: 1rem;
            color: ${theme.colors.variants.neutralDark4};
            border-bottom: 0.125rem solid
              ${error
                ? theme.colors.variants.dangerLight
                : theme.colors.variants.neutralLight2};
            padding-bottom: 0.375rem;
          `}
    `
  }}
`

interface ContainerProps {
  error: boolean
  isDirty: boolean
  variant: Variant
}

export type Variant = 'text' | 'checkbox' | 'submit'
