import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    )
  }),
)
Textarea.displayName = 'Textarea'

const Container = styled.textarea`
  width: 100%;
  height: 6.25rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 0.625rem;
  padding: 0.75rem 1.125rem;
  resize: none;
`
